import moment from "moment";
import datiInit from "../util/datiInit";
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import datiGen from "../util/datiGen";





export default class restVeo {
  constructor(restGruppi) {


  }

  restUrlServer = datiInit.getInstance().getRestUrlServer();

  restApp = 'veo';
  static myInstance = null;

  static getInstance() {
    if (restVeo.myInstance == null) {
      restVeo.myInstance = new restVeo();
      // Util.myInstance.inizializza();

    }
    return restVeo.myInstance;
  }

  postVeoStreamUrl = async (paddr) => {
    let pidsoc=datiGen.getInstance().getActiveSoc().sigla;
    let purl = pidsoc + '/';
    const user = getAuth().currentUser;
    var token = await user.getIdToken();
    var json = { esito: "KO" };
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();
      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + purl+ 'v1/postVeoStreamUrl', {
        method: "POST",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        },
        body: JSON.stringify({indirizzo:paddr})
      }
      );
      var json = await response.json();
      if (json) {
        if (json.esito && json.esito.esito != "OK") {
             json.esito = { esito: "KO2" };
        }
             }
    }
    catch (error) {
      console.error("getConfs", error);
       json = { esito: "KO7",des:error };
    } finally {

      if (!json) json = { esito: "KO3" };
      if (!json.esito) json.esito = 'KO';
      return json;

    }

    

  }

  getVeoStreamUrl = async () => {
    let pidsoc=datiGen.getInstance().getActiveSoc().sigla;
    let purl = pidsoc + '/';
    const user = getAuth().currentUser;
    var token = await user.getIdToken();
    var json = { esito: "KO" };
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();
      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + purl+ 'v1/getVeoStreamUrl', {
        method: "POST",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        }
      }
      );
      var json = await response.json();
      if (json) {
        if (json.esito && json.esito.esito != "OK") {
             json.esito = { esito: "KO2" };
        }
             }
    }
    catch (error) {
      console.error("getConfs", error);
       json = { esito: "KO7",des:error };
    } finally {

      if (!json) json = { esito: "KO3" };
      if (!json.esito) json.esito = 'KO';
      return json;

    }

    

  }

}
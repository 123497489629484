

export default class datiInit {

  constructor(datiGen) {

 
    this.restUrlServer= '#';
    this.amb='#';
    //utente
    //profiloutente
  }



  static myInstance = null;

  static getInstance() {
    if (datiInit.myInstance == null) {
      datiInit.myInstance = new datiInit();
      // Util.myInstance.inizializza();
      datiInit.myInstance.inizializza().then(() => console.log('Inizializing'));
    }



    return datiInit.myInstance;
  }


  inizializza() {
    return new Promise((resolve, reject) => {   
      this.caricaamb();
    });
  }
  caricaamb()
{
  console.log("qui 0",process.env.NODE_ENV);

  if (process.env.NODE_ENV && process.env.NODE_ENV.toLowerCase() === "development") {
    this.amb='DEV';
   //this.restUrlServer= '#';
 //this.restUrlServer= 'http://localhost:3000';
this.restUrlServer= 'https://ws2.vigormilano.it';
 // this.restUrlServer= 'https://ws2.vigormilano.it';
//salva dd
   // this.restUrlServer= 'http://192.168.10.75:3000';
   // this.restUrlServer= 'http://192.168.10.3:3000';
  }
 
  if (process.env.NODE_ENV && process.env.NODE_ENV.toLowerCase() === "production" ) {
   // console.log("qui 1","Dentro");
    this.amb='PROD';
//  this.restUrlServer= 'https://vigorapp.bs4.it';
this.restUrlServer= 'https://ws2.vigormilano.it';
  }
//  console.log("qui 1",this.restUrlServer);
}


getRestUrlServer()
{
  
  return this.restUrlServer;
}

  getAmb()
  {
    return this.amb;
  }






}

import moment from "moment";
import ProfiloUtente from "../obj/ProfiloUtente";
import restProfiloUtente from "../restcall/restProfiloUtente";
import datiInit from "../util/datiInit";
import restConf from '../restcall/restConf';



export default class datiGen {

  constructor(datiGen) {

    this.elsoc = new Map();
    this.activesoc = { sigla: '##', des: ' ' };
    this.elruolifunz = [];
    this.elruolifunzGruppoSQ = [];
    this.caricaprofiloincorso = 0;
    this.conf = new Map();

    //filtri ricerca anag 
    this.ricercacog = '';
    this.ricercanom = '';
    this.ricercaanno = '';

    //filtri ricarcaatt
    this.ricercaDtDa = moment().subtract(1, 'days').toDate();
    this.ricercaDtA = moment().add(10, 'days').toDate();
    this.ricercaTipogrp = '--';
    this.ricercaTipoAtt = "";
    this.ricercaTipoPartita = "";
    this.ricercaIndirizzo = "";
    this.ricercaGruppi = "";
    this.ordinamentoAtt = "A";
    
    //utente
    //profiloutente
  }



  static myInstance = null;

  static getInstance() {
    if (datiGen.myInstance == null) {
      datiGen.myInstance = new datiGen();
      // Util.myInstance.inizializza();
      datiGen.myInstance.inizializza().then(() => console.log('Inizializing'));
    }



    return datiGen.myInstance;
  }


  inizializza() {
    return new Promise((resolve, reject) => {
      this.elsoc = new Map();
      this.elsoc.set('VM', { sigla: 'VM', des: 'A.S.D. Vigor Milano ' });
      this.elsoc.set('ALD', { sigla: 'ALD', des: 'Aldini' });
      this.elsoc.set('AF1', { sigla: 'AF1', des: 'Società Affiliata 1' });
      this.elsoc.set('DE', { sigla: 'DE', des: 'A.S.D. DEMO Milano ' });

      this.setActiveSoc({ sigla: '##', des: ' ' });
      this.caricaruolifunz();
      this.caricaruolifunzGruppoSQ();
      datiInit.getInstance().inizializza();
         })
  }

  caricaruolifunz() {
    //mappa in id inserisco funzione nel valore inserisco ruolo
    this.elruolifunz = new Map();
    this.elruolifunz.set('LOGIN', 'LOGINPWA');  //non può essere modificato deve restare login login
    this.elruolifunz.set('LOGIN2', 'LOGIN');
    this.elruolifunz.set('ANAG', 'ANAG');
    this.elruolifunz.set('ANAGDETT', 'ANAGDETT');
    this.elruolifunz.set('ANAGPAG', 'ANAGPAG');
    this.elruolifunz.set('ANAGRECAPITI', 'ANAGRECAPITI');
    this.elruolifunz.set('LINKLISTAANAG_UI', 'ANAGDETT');
    this.elruolifunz.set('PAG', 'SEGRETERIA');
    this.elruolifunz.set('ISCRIZIONI', 'SEGRETERIA');
    this.elruolifunz.set('ANAGUPD', 'ANAGUPD');
    this.elruolifunz.set('ANAGINS', 'ANAGINS');
    this.elruolifunz.set('GRUPPI', 'GRUPPI');
    this.elruolifunz.set('GRUPPIUPD', 'GRUPPIUPD');
    this.elruolifunz.set('GRUPPI_UI', 'GRUPPIUPD');
    this.elruolifunz.set('ATTIVITA', 'LOGINPWA');
    this.elruolifunz.set('ATTIVITAUPD', 'ATTIVITAUPD');
    this.elruolifunz.set('ELENCOTEST', 'ELENCOTEST');
    this.elruolifunz.set('TESTATTIVITA', 'ATTIVITAUPD');   /// PER INSERIRE E ELIMINARE TEST IN ATTIVITà
    this.elruolifunz.set('ATTIVITADUPLICA', 'ATTIVITAUPD');
    this.elruolifunz.set('SCHGIOC', 'SCHGIOC');    //consente la visualizzazione
    this.elruolifunz.set('schedaGioc', 'SCHGIOC');  
    this.elruolifunz.set('SCHGIOCUPD', 'SCHGIOC');   //consente la maodifica
    this.elruolifunz.set('SCHGIOC_UI', 'ALLSCHGIOC');  //mostra il menu per accedere alla funzione
    this.elruolifunz.set('CAMBIASOC', 'CambiaSoc');
    this.elruolifunz.set('SCHOSS', 'SCHOSS');       //può vedere le schede osservatore
    this.elruolifunz.set('SCHOSSUPD', 'SCHOSS');   //può inserire le schede  osservatore e inviare notifiche
    this.elruolifunz.set('SCOUTING', 'Scouting');      //vede la scheda scouting senza passare da anag
    this.elruolifunz.set('SCOUTING_NOTIFY', 'ScoutingNotifiche');   //ScoutingNotifiche
    this.elruolifunz.set('ADMIN_NOTIFY', 'IT');   //
    this.elruolifunz.set('PROFILIUTENTE', 'IT');   //
    this.elruolifunz.set('VEO', 'VEO');   //
    this.elruolifunz.set('ORDINI', 'LOGINPWA');   
    this.elruolifunz.set('GESTIONEORDINI', 'GESTIONEORDINI');   
    this.elruolifunz.set('APPROVAORDINI', 'APPROVAORDINI');   
    this.elruolifunz.set('GIOCGRUPPO', 'GIOCGRUPPO');   //
    this.elruolifunz.set('GIOCGRUPPO_ASSOCIA', 'GIOCGRUPPO_ASSOCIA');   //
    this.elruolifunz.set('ALLGIOCATTIVITAUPD', 'ALLGIOCATTIVITAUPD'); //permette di vedere e aggiornare presenze e conv fuori dai gruppi in modo globale
    this.elruolifunz.set('GiocAttivita', 'ATTIVITAUPD');
    this.elruolifunz.set('GiocAttivitaUPD', 'ATTIVITAUPD'); 
    this.elruolifunz.set('TABPARTITA', 'TABPARTITA');
    this.elruolifunz.set('REPORTPRESENZE', 'REPORTPRESENZE'); //permette di vedere e aggiornare report presenze 
    this.elruolifunz.set('COMMPARTITA', 'ALLGIOCATTIVITAUPD');  //commento tecnico partita
    this.elruolifunz.set('CONF', 'LOGINPWA');
    this.elruolifunz.set('CONFIGURAZIONI', 'LOGINPWA');
    this.elruolifunz.set('TESTCONF', 'IT');
    this.elruolifunz.set('CONFAPACITA', 'IT');
    this.elruolifunz.set('MOSTRAID', 'IT');           //// serve per tutti gli id esempio opecreaz in attività
    this.elruolifunz.set('ESECTEST', 'TEST');
    this.elruolifunz.set('COMPLEANNI', 'LOGINPWA');
    this.elruolifunz.set('REPTEST', 'IT');
    this.elruolifunz.set('REPTESTSQUADRA', 'IT');
    this.elruolifunz.set('ListaGioc', 'ALLGIOCATTIVITAUPD');
    this.elruolifunz.set('STATGIOC', 'ALLGIOCATTIVITAUPD');
    
  }

  caricaruolifunzGruppoSQ() {
    //mappa in id inserisco id gruppo squadra nel valore inserisco mappa seguente
    this.elruolifunzGruppoSQ = new Map();

    var dirittisq = new Map();
    //mappa in id inserisco funzione nel valore inserisco ruolo
    dirittisq.set('LOGIN', 'VIGOR');
    dirittisq.set('ListaGioc', 'VIGOR');
    dirittisq.set('ANAGDETT', 'VIGORGIOCUPD');
    dirittisq.set('ANAGRECAPITI', 'VIGORRECAPITI');
    dirittisq.set('PAG', 'SEGRETERIA');
    dirittisq.set('ISCRIZIONI', 'SEGRETERIA');
    dirittisq.set('ANAGUPD', 'VIGORGIOCUPD');
    dirittisq.set('ATTIVITA', 'VIGOR');
    dirittisq.set('ATTIVITAUPD', 'VIGORATTUPD');
    dirittisq.set('schedaGioc', 'VIGORSCHEDA');   //mostra menu nel gruppo per accedere scheda gioc
    dirittisq.set('GiocAttivita', 'VIGORGIOCUPD');
    dirittisq.set('TABPARTITA', 'VIGORATTUPD');
    dirittisq.set('GiocAttivitaUPD', 'VIGORATTUPD');
    dirittisq.set('REPORTPRESENZE', 'VIGORPRESENZE');
    dirittisq.set('COMMPARTITA', 'VIGORSCHEDA');              //commento tecnico partita
    dirittisq.set('ELENCOTEST', 'VIGORELENCOTEST');
    dirittisq.set('TESTATTIVITA', 'VIGORATTUPD');               /// PER INSERIRE E ELIMINARE TEST IN ATTIVITà
    dirittisq.set('ATTIVITADUPLICA', 'VIGORATTUPD');
    dirittisq.set('REPTEST', 'IT');       //// report singolo valore per gruppo
    dirittisq.set('REPTESTSQUADRA', 'IT');  
    dirittisq.set('VEO', 'VEO');  
    dirittisq.set('ORDINI', 'VIGOR'); 
    dirittisq.set('GESTIONEORDINI', 'GESTIONEORDINI'); 
    dirittisq.set('APPROVAORDINI', 'APPROVAORDINI'); 
    dirittisq.set('STATGIOC', 'VIGORATTUPD'); 
    dirittisq.set('ESECTEST', 'TEST');



    this.elruolifunzGruppoSQ.set('**', dirittisq);

  }


  getruolifunzGruppoSQ(sq) {
    var tmpmap;
    //mappa in id inserisco id gruppo squadra nel valore inserisco mappa seguente
    if (this.elruolifunzGruppoSQ.has(sq))
      tmpmap = this.elruolifunzGruppoSQ.get(sq);
    else {

      tmpmap = this.elruolifunzGruppoSQ.get('**');
    }
    let out = [];
    tmpmap.forEach((val, key) => { if (out.findIndex((ele) => ele.value == val) == -1) out.push({ label: val, value: val }); });
    return out;
  }



  getruolifunz() {


  let  out = [];
    this.elruolifunz.forEach((val, key) => { if (out.findIndex((ele) => ele.value == val) == -1) out.push({ label: val, value: val }); });
    return out;
  }




  getValutazioniRuolo(r) {

    let vals = [];
    if (r.startsWith('#P01')) {
      vals.push({ codice: 'PT01', descrizione: 'Tecnica di base', val: 0 });
      vals.push({ codice: 'PC01', descrizione: 'Controllo di palla', val: 0 });
      vals.push({ codice: 'PT02', descrizione: 'Tuffo Sinistra', val: 0 });
      vals.push({ codice: 'PT03', descrizione: 'Tuffo Destra', val: 0 });
      vals.push({ codice: 'PU01', descrizione: 'Uscita alta', val: 0 });
      vals.push({ codice: 'PU02', descrizione: 'Uscita bassa', val: 0 });
      vals.push({ codice: 'PP01', descrizione: 'Presa alta', val: 0 });
      vals.push({ codice: 'PP02', descrizione: 'Presa bassa', val: 0 });
      vals.push({ codice: 'PP03', descrizione: 'Presa mezza altezza', val: 0 });
      vals.push({ codice: 'PR05', descrizione: 'Rialzata', val: 0 });
      vals.push({ codice: 'PP10', descrizione: 'Personalità', val: 0 });
      vals.push({ codice: 'PF01', descrizione: 'Forza', val: 0 });
      vals.push({ codice: 'PR01', descrizione: 'Rapidità', val: 0 });
      vals.push({ codice: 'PP20', descrizione: 'Piazzamento', val: 0 });
      vals.push({ codice: 'PA01', descrizione: 'Agilità', val: 0 });

    }
    else {
      vals.push({ codice: 'GT01', descrizione: 'Tecnica di base', val: 0 });
      vals.push({ codice: 'GTD1', descrizione: 'Destro', val: 0 });
      vals.push({ codice: 'GTS1', descrizione: 'Sinistro', val: 0 });
      vals.push({ codice: 'GTT1', descrizione: 'Colpo di testa', val: 0 });
      vals.push({ codice: 'GP01', descrizione: 'Precisione', val: 0 });
      vals.push({ codice: 'GV01', descrizione: 'Visione di gioco', val: 0 });
      vals.push({ codice: 'GS01', descrizione: 'Smarcamento', val: 0 });
      vals.push({ codice: 'GM01', descrizione: 'Marcamento', val: 0 });
      vals.push({ codice: 'G101', descrizione: '1c1 Offensivo', val: 0 });
      vals.push({ codice: 'G102', descrizione: '1c1 Difensivo', val: 0 });
      vals.push({ codice: 'GC01', descrizione: 'Contrasto', val: 0 });
      vals.push({ codice: 'GF01', descrizione: 'Forza', val: 0 });
      vals.push({ codice: 'GV01', descrizione: 'Velocità', val: 0 });
      vals.push({ codice: 'GA01', descrizione: 'Agilità', val: 0 });
      vals.push({ codice: 'GV02', descrizione: 'Progressione', val: 0 });
      vals.push({ codice: 'GC02', descrizione: 'Coordinazione', val: 0 });

    }

    return vals;
  }


  getValutazioniOssRuolo(r) {

    let vals = [];
    if (r.startsWith('#P01')) {
      vals.push({ codice: 'POT01', descrizione: 'Tecnica di base', val: 0 });
      vals.push({ codice: 'POC01', descrizione: 'Controllo di palla', val: 0 });
      vals.push({ codice: 'POT02', descrizione: 'Tuffo Sinistra', val: 0 });
      vals.push({ codice: 'POT03', descrizione: 'Tuffo Destra', val: 0 });
      vals.push({ codice: 'POU01', descrizione: 'Uscita alta', val: 0 });
      vals.push({ codice: 'POU02', descrizione: 'Uscita bassa', val: 0 });
      vals.push({ codice: 'POP01', descrizione: 'Presa alta', val: 0 });
      vals.push({ codice: 'POP02', descrizione: 'Presa bassa', val: 0 });
      vals.push({ codice: 'POP03', descrizione: 'Presa mezza altezza', val: 0 });
      vals.push({ codice: 'POR05', descrizione: 'Rialzata', val: 0 });
      vals.push({ codice: 'POP10', descrizione: 'Personalità', val: 0 });
      vals.push({ codice: 'POF01', descrizione: 'Forza', val: 0 });
      vals.push({ codice: 'POR01', descrizione: 'Rapidità', val: 0 });
      vals.push({ codice: 'POP20', descrizione: 'Piazzamento', val: 0 });
      vals.push({ codice: 'POA01', descrizione: 'Agilità', val: 0 });

    }
    else {
      vals.push({ codice: 'GOD01', descrizione: '1c1 Difensivo', val: 0 });
      vals.push({ codice: 'GOD02', descrizione: 'Anticipa/Intercetta', val: 0 });
      vals.push({ codice: 'GOD03', descrizione: 'Palle Recuperate', val: 0 });
      vals.push({ codice: 'GOD04', descrizione: 'Palle Alte', val: 0 });
      vals.push({ codice: 'GOD05', descrizione: 'Falli commessi', val: 0 });
      vals.push({ codice: 'GOD06', descrizione: 'Contrasti Vinti', val: 0 });
      vals.push({ codice: 'GOD07', descrizione: 'Contrasti Persi', val: 0 });

      vals.push({ codice: 'GOA01', descrizione: 'Gol', val: 0 });
      vals.push({ codice: 'GOA02', descrizione: 'Tiri', val: 0 });
      vals.push({ codice: 'GOA03', descrizione: 'Passaggi Indietro', val: 0 });
      vals.push({ codice: 'GOA04', descrizione: 'Passaggi orizzontali', val: 0 });
      vals.push({ codice: 'GOA05', descrizione: 'Passaggi avanti', val: 0 });
      vals.push({ codice: 'GOA06', descrizione: 'Passaggi Prima', val: 0 });
      vals.push({ codice: 'GOA07', descrizione: 'Passaggi Lunghi', val: 0 });
      vals.push({ codice: 'GOA08', descrizione: 'Cross', val: 0 });
      vals.push({ codice: 'GOA09', descrizione: '1c1 Offensivo', val: 0 });
      vals.push({ codice: 'GOA10', descrizione: 'Palle Perse', val: 0 });
      vals.push({ codice: 'GOA11', descrizione: 'Riceve e mantiene', val: 0 });
      vals.push({ codice: 'GOA12', descrizione: 'Falli subiti', val: 0 });
      vals.push({ codice: 'GOA13', descrizione: 'Giocate determinanti', val: 0 });
      vals.push({ codice: 'GOA14', descrizione: 'Sovrapposizioni', val: 0 });


      vals.push({ codice: 'GOA15', descrizione: 'Velocità', val: 0 });
      vals.push({ codice: 'GOA16', descrizione: 'Progressione', val: 0 });
      vals.push({ codice: 'GOA17', descrizione: 'Destro', val: 0 });
      vals.push({ codice: 'GOA18', descrizione: 'Sinistro', val: 0 });
      vals.push({ codice: 'GOA19', descrizione: 'Colpo di Testa', val: 0 });
      vals.push({ codice: 'GOA20', descrizione: 'Posizione', val: 0 });

    }

    return vals;
  }

  prova() {
    console.log('prova');
  }


  async caricaProfiloUtente(putente) {
    //  console.log('caricaProfiloUtente','inizio' );
    if (putente && (this.caricaprofiloincorso == putente.uid || this.caricaprofiloincorso == 1)) {
      return;
    }
    //   console.log('caricaProfiloUtente','1' );
    if (putente)
      this.caricaprofiloincorso = putente.uid;
    else
      this.caricaprofiloincorso = 1;
    let trovato = 0;
    var json = "";
    var json2 = "";
    // console.log('caricaProfiloUtente','2' );
    if (putente) {
      //    console.log('caricaProfiloUtente','3' );
      try {
        json = await restProfiloUtente.getInstance().getProfiloUtente(putente.uid);
        //  console.log('profiloutente',json);
        if (!json.idutente) {
          trovato = 0;
          //    console.log('caricaProfiloUtente','4' );
        }
        else {
          //    console.log('aaaa');
          this.utentecollegato = json;
          trovato = 1;
        }
      } catch (error) {
        //   console.log('bbbb');

        trovato = 0;
      }
      //  console.log('caricaProfiloUtente','5',trovato );
      if (trovato == 0) {

        this.utentecollegato = new ProfiloUtente();
        this.utentecollegato.idutente = putente.uid;
        this.utentecollegato.displayName = putente.displayName;
        this.utentecollegato.email = putente.email;
        this.utentecollegato.ruoli.push({ idsoc: "##", gruppi: ["LOGIN"] });
        this.utentecollegato.authuser = putente;
        this.activesoc = { sigla: '##', des: ' ' };

        try {
          //    console.log('caricaProfiloUtente','6' );
          await restProfiloUtente.getInstance().aggiungi(this.utentecollegato);
          //     console.log('caricaProfiloUtente','7' );
          json2 = await restProfiloUtente.getInstance().getProfiloUtente(putente.uid);
          //     console.log('caricaProfiloUtente','8');
          //     console.log('caricaProfiloUtente','8',json2 );
          this.utentecollegato = json2;
          //    console.log('caricaProfiloUtente','9');

        } catch (error) {
          console.error(error);
        }
      }

    }
    else {  //se logout per mettere nullo

      this.utentecollegato = putente;
      this.activesoc = { sigla: '##', des: ' ' };
    }

    //  console.log('caricaProfiloUtente','quasi fine' );
    if (this.utentecollegato) {
      this.setActiveSoc(this.utentecollegato.idsoc);

    }
    this.caricaprofiloincorso = 0;

  }


  setActiveSoc(sigla) {
    this.activesoc = this.elsoc.get(sigla);
    this.caricaConf();
  }

  getActiveSoc() {
    if (!this.activesoc)
      this.activesoc = { sigla: '##', des: ' ' };
    return this.activesoc;
  }

  getUtente() {
    return this.utente;
  }

  getUtenteCollegato() {
    return this.utentecollegato;
  }

  getStagioneAttiva() {

    var stag = "";
    if (moment().month() >= 6 && moment().month() < 12) {
      stag = moment().format('YYYY') + "-" + moment().add(1, 'years').format('YYYY');
    }
    else {
      stag = moment().subtract(1, 'years').format('YYYY') + "-" + moment().format('YYYY');
    }
    return stag;
  }







  async sendMsgTokenRegistration(token) {
    await restProfiloUtente.getInstance().sendMsgTokenRegistration(token);
  }



  async setUtente(user) {
    return new Promise((resolve, reject) => {
      this.utente = user;
      this.caricaProfiloUtente(this.utente).then(() => {
        resolve(this.utentecollegato);

      });
    }
      , (error) => {
        console.log('getpersona_fromrowid DB error:', error);
        
      });


  }
  getNomeUtenteCollegato() {
    var nu = "xx";
    //console.log(this.utentecollegato);
    if (this.utentecollegato) nu = this.utentecollegato.displayName;
    return nu;
  }

  possoaccedere(pfunz, pgruppo) {
return this.possoaccederev2(pfunz,pgruppo);
  }



  possoaccederev2(pfunz, pgruppo) {

    var out = false;
    var vidsoc = "##";
    var caso = '';
    var vruoli, vruoligrp;
    var ruolo, ruologrp;
    if (this.activesoc && this.activesoc.sigla)
      vidsoc = this.activesoc.sigla;
    try {
      var ruolo = this.elruolifunz.get(pfunz);
      if (this.utentecollegato && this.utentecollegato.ruoli) {
        vruoli = this.utentecollegato.ruoli.find(element => element.idsoc == vidsoc);
        if (vruoli && vruoli.gruppi) {

          out = vruoli.gruppi.includes(ruolo);
          if (!out) {
            caso = caso + ' caso1 ';
          }
        }
        else {
          out = false;
          caso = caso + ' caso2 ';
        }
      }
    }
    catch (error) {
      console.error(error);
      out = false;
      caso = caso + ' caso3 ';
    }



    if (!out && pgruppo)  //controllo ruoli con gruppo squadra
    {
      //verifico se sono stati indicati i ruoli per gruppo sq altrimenti prendo predefinito;
      var elruoli = this.elruolifunzGruppoSQ.get(pgruppo);
      if (!elruoli)  //se non trovo i ruoli uso soc ** predefinita
      {
        elruoli = this.elruolifunzGruppoSQ.get('**');
      }


      try {
        ruologrp = elruoli.get(pfunz);
        if (this.utentecollegato && this.utentecollegato.ruoliGruppiSquadra) {
          vruoligrp = this.utentecollegato.ruoliGruppiSquadra.find(element => element.idsoc == vidsoc && element.grupposq == pgruppo);


          if (vruoligrp && vruoligrp.diritti) {
            //console.log('GRUPPI',vruoli.gruppi);
            out = vruoligrp.diritti.includes(ruologrp);
            if (!out) {
              caso = caso + ' caso5 ';
              //  console.log('possoaccederev2', 'NON AUTORIZZATO', 'Gruppo SQ:', pgruppo, 'Funzione:', pfunz, 'Gruppo richiesto:', ruolo, 'GRUPPI UTENTE:', vruoli.gruppi);*/
            }
          }
          else {
            out = false;
            caso = caso + ' caso6 ';
            //console.log('possoaccederev2', 'Ruoli utente non configurati', 'funzione:', pfunz, 'Ruolo richiesto:', ruolo);
          }
        }
      }
      catch (error) {
        console.error(error);
        out = false;
        caso = caso + ' caso7 ';
        console.error("possoaccederev2 ",caso,error);
      }
    }
   /* if (!out)   ///abilitare per controllare errore diritti
      console.log('possoaccederev2', 'NON AUTORIZZATO', 'Gruppo SQ:', pgruppo, 'Funzione:', pfunz, 'ruolo richiesto:', ruolo, '   Gruppo richiesto per gruppi:', ruologrp,
        'GRUPPI UTENTE:', vruoli ? vruoli.gruppi : '', 'gruppi utenti per gruppi:', vruoligrp ? vruoligrp.diritti : '');
*/

     //   console.log('possoaccedereV2', 'Ruoli utente non configurati', 'funzione:', pfunz, 'Ruolo richiesto:', ruolo+" "+caso);
    return out;
  }


  replaceCharAt = (str, index, char) => {
    let prima = str.slice(0, index);
    let dopo =  str.slice(index + 1);
    console.log(prima + char + dopo);
    return prima + char + dopo;
  }

  scala = (size) => {
   /* const ratio = PixelRatio.get();
    const { width, height } = Dimensions.get('window');

    if (width / ratio < 100) {

      size = size * 0.6;
    }
    else if (width / ratio < 200) {
      size = size * 0.75;
    }
    else if (width / ratio < 300) {
      size = size * 0.7;
    }
    else if (width / ratio < 400) {
      size = size * 0.8;
    }
    else if (width / ratio < 500) {
      size = size * 0.9;
    }
    else if (width / ratio < 800) {
      size = size * 0.9;
    }
    else {
      size = size;
    }
*/

    return size;
  };


  caricaConf = () => {

    if (!this.conf)
      this.conf = new Map();
    //console.log('CaricaConf',this.getActiveSoc().sigla);

    if (this.getActiveSoc().sigla != '##') {
      restConf.getInstance().getConfs(this.getActiveSoc().sigla).then((json) => {
        console.log('caricaconf',json);
        if (json.esito=='OK')
        {
        json.forEach((ele) => {
          this.conf.set(ele.codice, ele);
        }
      
        )}
        setTimeout(() => { this.caricaConf(); }, 1000 * 60 * 60 * 24);

      })
    }
    //console.log('CaricaConf',this.getActiveSoc().sigla);
  }



  scarica_aggiornamento = () => {
    const purl = this.getConf('URL_UPD').valore;

   // Linking.openURL(purl);

  }


  getConf = (p_cod_conf) => {

    const t = this.conf.get(p_cod_conf);

    return t;
  }

  getElencoConf = (p_cod_conf) => {

    
    return this.conf.get(p_cod_conf).elencoVal;
  }



  getRicercaCog = () => {
    return this.ricercacog;
  }
  getRicercaNom = () => {
    return this.ricercanom;
  }
  getRicercaAnno = () => {
    return this.ricercaanno;
  }

  setRicercaCog = (pval) => {
    this.ricercacog = pval;
  }
  setRicercaNom = (pval) => {
    this.ricercanom = pval;
  }
  setRicercaAnno = (pval) => {
    this.ricercaanno = pval;
  }

  getRicercadtDa = () => {
    return this.ricercaDtDa;
  }
  getRicercadtA = () => {
    return this.ricercaDtA;
  }
  getRicercaTipoAtt = () => {
    return this.ricercaTipoAtt;
  }
  getRicercaIndirizzo = () => {
    return this.ricercaIndirizzo;
  }
  getRicercaGruppi = () => {
    return this.ricercaGruppi;
  }
  getRicercaTipogrp = () => {
    return this.ricercaTipogrp;
  }
 getRicercaTipoPartita = () => {
    return this.ricercaTipoPartita;
  }
  setRicercaDtDa = (pval) => {
    this.ricercaDtDa = pval;
  }
  setRicercaDtA = (pval) => {
    this.ricercaDtA = pval;
  }
  setRicercaTipoAtt = (pval) => {
    this.ricercaTipoAtt = pval;
  }
  setRicercaGruppi = (pval) => {
    this.ricercaGruppi = pval;
  }
  setRicercaIndirizzo = (pval) => {
    this.ricercaIndirizzo = pval;
  }
  setOrdinamentoAtt = (pord) => {
    this.ordinamentoAtt = pord;
  }
  setRicercaTipogrp = (pval) => {
    this.ricercaTipogrp = pval;
  }
  setRicercaTipoPartita = (pval) => {
    this.ricercaTipoPartita = pval;
  }
  getOrdinamentoAtt = () => {
    return this.ordinamentoAtt;
  }


/*
  getlogo = () => {
    var RNFS = require('react-native-fs');
    if (Platform.OS === 'android') {
      return RNFS.readFileAssets('logo-vigor3.png', 'base64').then((dat) => {

        return dat;

      });
    }
    else {
     return RNFS.readFile(RNFS.MainBundlePath + '/logo-vigor3.png', 'base64').then((dat) => {
        return dat;
      });
    }


  };

*/
creaID = (prefisso) => {
  const minCeiled = Math.ceil(0);
  const maxFloored = Math.floor(100);
  let k = Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled);


  let cid = (prefisso ? prefisso : "I") + moment().format("YY") + "-" + new Date().getTime() + "-" + k.toString().padStart(3, '0');
  return cid;


}








}
import moment from "moment";
import datiInit from "../util/datiInit";
import { getAuth } from "firebase/auth";
import datiGen from "../util/datiGen";




export default class restGruppi {
  constructor(restGruppi) {
    
    
  }

  restUrlServer = datiInit.getInstance().getRestUrlServer();

  restApp = 'gruppi';
  static myInstance = null;

  static getInstance() {
    if (restGruppi.myInstance == null) {
      restGruppi.myInstance = new restGruppi();
      // Util.myInstance.inizializza();
      
    }
    return restGruppi.myInstance;
  }



  getGruppi = async (mostravalidi) => {
    let purl="";
    if ( mostravalidi==false)
     {
       purl="?mostravalidi=false";
     }
     else if (mostravalidi==undefined || mostravalidi==true)
     {
      purl="?mostravalidi=true";
    }
     else
     {
      console.log('listagruppi','getgruppi','else - qui non devo entrare');
     }

    const user=getAuth().currentUser;
    var token = await user.getIdToken();

    
    var json ={esito:"KO"};
    try {
      const user=getAuth().currentUser;
      var token = await user.getIdToken();
     // console.log("getGruppi",purl);
      const response = await fetch(this.restUrlServer + '/' + this.restApp  + '/' + datiGen.getInstance().getActiveSoc().sigla + purl , {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization':"Bearer "+ token
        }
      }
      );
      var json = await response.text();

if (json)
   json=JSON.parse(json);
   else json={esito:"KO2"};

      
    } catch (error) {
      console.error(error);
      
    } finally {
      
      if (!json) json={esito:"KO3"};
      if (!json.esito) json.esito='KO';
      
     
      return json;
      
    }
    
  }
  getGruppiPred = async () => {
       

    const user=getAuth().currentUser;
if (!user) return {esito:"KO",des:"Utente non inizializzato"};


    var token = await user.getIdToken();

    
    var json ={esito:"KO"};
    try {
      const user=getAuth().currentUser;
      var token = await user.getIdToken();
     // console.log("getGruppi",purl);
      const response = await fetch(this.restUrlServer + '/' + this.restApp  + '/' + datiGen.getInstance().getActiveSoc().sigla + '/gruppi/predefinito' , {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization':"Bearer "+ token
        }
      }
      );
      var json = await response.text();

if (json && json!='Not Found')
   json=JSON.parse(json);
   else json={esito:"KO2"};

      
    } catch (error) {
      console.log("getGruppiPred","json",json);
      console.error("getGruppiPred",error);
      
    } finally {
      
      if (!json) json={esito:"KO3"};
      if (!json.esito) json.esito='KO';
      
     
      return json;
      
    }
    
  }

  aggiungi = async (grp) => {
    var vgrp={...grp};
    var json ={esito:"KO"};
    console.log('restgruppi',grp,datiGen.getInstance().getActiveSoc().sigla,vgrp);
    try {
      const user=getAuth().currentUser;
      var token = await user.getIdToken();
      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla 
        , {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+ token
          },
          body: JSON.stringify(vgrp)
        });
     json= await  response.json();
     
     if (response.status>=300)
      {
       console.log("restGruppi","aggiungi","--",response.status,json);
       json.esito='KO'
       throw 'Errore '+JSON.stringify(json);
      }
      else
      json.esito='OK'

    } catch (error) {
      console.error(error);
      json.esito='KO'
    } finally {
      if (!json) json={esito:"KO"};
      if (!json.esito) json.esito='KO';
      return JSON.stringify(json);
    }
  }

  modifica = async (grp) => {
    // console.log("Modifica",ppers,ppers.nome);
    try {
      const user=getAuth().currentUser;
      var token = await user.getIdToken();
      
      var p = { ...grp };
      delete p._id;
      
      const s = this.restUrlServer + '/' + this.restApp  + '/' + datiGen.getInstance().getActiveSoc().sigla + '/' + grp._id;
      
      const response = await fetch(s
        , {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+ token
          },
          body: JSON.stringify(p)
        });

      const json = await response.json();
      return json;

    } catch (error) {
      console.error(error);
    } finally {
      

    }

  }
 
  getGruppo = async (gid) => {
    const user = getAuth().currentUser;
    var token = await user.getIdToken();


//console.log(gid);

    if (!datiGen.getInstance().possoaccederev2('ListaGioc',gid))
    throw 'Non sei abilitato a svolgere la funzione getGruppo';

    try {

      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + "/" + gid, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        }
      }
      );
      const json = await response.json();
      if (response.status >= 300) {
      console.log("restPersona", "getPersona", "--", response.status, json);
        throw 'Errore ' + JSON.stringify(json);
      }
      return json;
    } catch (error) {
      console.error(error);
      return "KO";
    } finally {
      //   console.log("finito getPersone");
    }
  }


}
import moment from "moment";

import { getAuth } from "firebase/auth";
import datiInit from "../util/datiInit";
import datiGen from "../util/datiGen";
import { parseTwoDigitYear } from "moment";
import { parseJsonConfigFileContent } from "typescript";


export default class restPersona {
  constructor(restPersona) {
   // console.log('restPersona');
  }

  restUrlServer = datiInit.getInstance().getRestUrlServer();
  restApp = 'Persons';
  static myInstance = null;

  static getInstance() {
    if (restPersona.myInstance == null) {
      restPersona.myInstance = new restPersona();
      // Util.myInstance.inizializza();
    //  console.log('fine get instance');
    }
    return restPersona.myInstance;
  }

  prova() {
    console.log('prova');
  }

  getPersone = async (pcog, pnom, panno,ptest,pgrp) => {
    console.log('getPersone',pgrp);
    var json = "";
    if (! (datiGen.getInstance().possoaccederev2('ANAG') || datiGen.getInstance().possoaccederev2('GiocAttivitaUPD',pgrp) )  ) {
      throw 'Non sei abilitato a svolgere la funzione ANAG e GiocAttivitaUPD';
    }


    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();
      var dtcond = "";
      
      if (panno && panno>1900 &&panno<2300) {
        dtcond = "&d1=" + new moment(panno + "-01-01").toDate().toISOString() + "&d2=" + new moment(panno + "-01-01").add(1, 'years').toDate().toISOString();
      }

      var testcond=ptest?'&test='+ptest:'';
      var grpcond=pgrp?'&gruppo='+pgrp:'';
     
      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + "?" + "cognome=" + pcog + "&nome=" + pnom + dtcond +testcond+grpcond
        , {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          }
        }
      );

      json = await response.json();
      
      if (response.status >= 300) {
        console.log("restPersona", "getPersone", "--", response.status, json);
        throw 'Errore ' + JSON.stringify(json);
      }
      return json;
    } catch (error) {
      console.error("getPersone", error);
      return "KO";
    } finally {
      //   console.log("finito getPersone");
    }
  }

  getCompleanni = async () => {
    var json = "";
    if (!datiGen.getInstance().possoaccederev2('COMPLEANNI')) {
      throw 'Non sei abilitato a svolgerexxx la funzione COMPLEANNI';
    }

    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();
      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + "/compleanni"  
        , {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          }
        }
      );

      json = await response.json();

      console.log('restpersona','compleanni',json);
      
      if (response.status >= 300) {
        
        throw 'Errore ' + JSON.stringify(json);
      }
      return json;
    } catch (error) {
      console.error("getCompleanni", error);
      return [];
    } finally {
         
    }
  }



  getPersona = async (pid,gruppo) => {
    const user = getAuth().currentUser;
    var token = await user.getIdToken();
var esci=1;

if (datiGen.getInstance().possoaccederev2('ANAGDETT',gruppo))
          {esci=0;
            }
else if (datiGen.getInstance().getUtenteCollegato().atleti && datiGen.getInstance().getUtenteCollegato().atleti.includes(pid))
     {esci=0;}
else if (datiGen.getInstance().possoaccederev2('SCHOSS',gruppo)  ||  datiGen.getInstance().possoaccederev2('SCHGIOC',gruppo)  )  
                    esci=0;
else throw 'Non sei abilitato a svolgere la funzione get persona';

if (esci==0)
{
try {
      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + "/" + pid+"?gruppo="+gruppo, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        }
      }
      );
      const json = await response.json();
      if (response.status >= 300) {
        console.log("restPersona", "getPersona", "--", response.status, json);
        throw 'Errore ' + JSON.stringify(json);
      }
      return json;
    } catch (error) {
      console.error(error);
      return "KO";
    } finally {
      //   console.log("finito getPersone");
    }
  }
  else throw 'Non sei abilitato a svolgere la funzione getpersona';

  }



  aggiungi = async (ppers,gruppo) => {
  console.log('restpersona','aggiungi','inizio',ppers, JSON.stringify(ppers));
    if (!datiGen.getInstance().possoaccederev2('ANAGINS',gruppo))
      throw 'Non sei abilitato a svolgere la funzione ANAGINS';
      
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();
      
      const s=this.restUrlServer + '/' + this.restApp+ '/'+datiGen.getInstance().getActiveSoc().sigla+'?gruppo='+gruppo;
      
      const response = await fetch(s
        , {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(ppers)
        });
        
      const json = await response.json();
      
      if (response.status >= 300) {
        console.warn("restPersona", "aggiungi", "--", response.status, json);
        throw 'Errore ' + JSON.stringify(json);
      }
      return json;

    } catch (error) {
      console.error(error);
    } finally {
      // console.log("finito aggiungi");
    }
  }

  modifica = async (ppers,gruppo) => {
    
    if (!datiGen.getInstance().possoaccederev2('ANAGUPD',gruppo))
      throw 'Non sei abilitato a svolgere la funzione ANAGUPD';

      console.log("Modifica",ppers,ppers.nome);
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();
      var p = { ...ppers };
      //delete p._id;
      const s = this.restUrlServer + '/' + this.restApp + '/'+datiGen.getInstance().getActiveSoc().sigla+ '/' + ppers._id+'?gruppo='+gruppo;
      // console.log("DettaglioPersona",'modifica',s,p);
      const response = await fetch(s
        , {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(p)
        });

      const json = await response.json();
      if (response.status >= 300) {
        console.log("restPersona", "getPersone", "--", response.status, json);
        throw 'Errore ' + JSON.stringify(json);
      }
      console.log('DettaglioPersona','modifica',json);
      return json;

    } catch (error) {
      console.error(error);
    } finally {
      //console.log('DettaglioPersona','modifica');

    }

  }


}